<template>
<div style="padding: 10px">
  <div style="margin-bottom: 10px">
    <Button type="primary" @click="goBack()">返回</Button>
  </div>
  <Row :gutter="10">
    <Col span="24" style="text-align: right">
      <Button type="primary" @click="exportsExcel()">导出</Button>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24">
      <Table stripe border :columns="columns" :data="list">
        <template slot="action" slot-scope="{row}">
          <Button style="margin-left: 5px" type="primary" @click="detail(row)">查看</Button>
        </template>
      </Table>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24" style="text-align: center">
      <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="searchKey.pagesize" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
    </Col>
  </Row>
</div>
</template>

<script>
import api from '../api/xiaochengxu';
export default {
  name: "Gzhpapersubmit",
  data: function(){
    return {
      searchKey: {
        page: 1,
        pagesize: 30,
        paper_id: '',
        user_id: ''
      },
      list: [],
      columns: [],
      total: 0,
    }
  },
  methods: {
    goBack: function(){
      this.$router.replace({path: '/gzhpaper', query: {id: this.searchKey.user_id}})
    },
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    getList: function(){
      this.$http.get(api.gzhPaperSubmitList, {params: this.searchKey}).then(response => {
        if(response.data.success) {
          for(let item of response.data.res.form) {
            if(item.paper_form_type === 'upload' || item.paper_form_type === 'textarea') continue;
            this.columns.push({title: item.paper_form_name, key: item.paper_form_field})
          }
          this.columns.push({title: '操作', slot: 'action', width: 120})
          this.list = response.data.res.table;
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    },
    detail: function(row){
      const paper_table_id = 'paper_table_' + this.searchKey.paper_id + '_id';
      this.$router.push({path: '/gzhpapersubmitdetail', query: {
          paper_id: this.searchKey.paper_id,
          paper_table_id: row[paper_table_id],
          user_id: this.searchKey.user_id
      }})
    }
  },
  mounted() {
    this.searchKey.paper_id = this.$route.query.id;
    this.searchKey.user_id = this.$route.query.uid;
    this.getList()
  }
}
</script>

<style scoped>

</style>